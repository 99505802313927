import React from 'react'
import { createRoot } from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import { register } from 'swiper/element/bundle'
import 'rsuite/dist/rsuite.min.css'
import './global.css'
import appRoutes from 'routes/App'
import adminRoutes from 'routes/Admin'
import registerServiceWorker from 'lib/registerServiceWorker'
import { createHashRouter } from 'react-router-dom/dist'
import { upgradeStorage } from 'lib/storage'

register()

const container = document.getElementById('root')
const root = createRoot(container)

const StartApp = () => {

	upgradeStorage()

	root.render(
		<React.StrictMode>
			<RouterProvider
				router={(
					window.cordova
						? createHashRouter(appRoutes.concat(adminRoutes))
						: createBrowserRouter(appRoutes.concat(adminRoutes))
				)}
			/>
		</React.StrictMode>
	)
	registerServiceWorker()
}

if (window.cordova) {
	document.addEventListener('deviceready', StartApp, false);
} else {
	StartApp();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
