import { get } from 'lib/storage'
import { fetchapi } from './fetch'
import { Suspense, lazy } from 'react'

export function isLoggedIn() {
	return (async () => {
		const profile = await get('Profile')

		return !!(profile?.id)
	})()
}

export function isAdmin() {
	return (async () => {
		const profile = await get('Profile')

		if (profile?.id) {
			return !!(profile.isAdmin)
		} else {
			return false
		}
	})()
}

export async function sendEmail(emailid, to) {
	if (emailid && to?.length > 0) {
		const response = await fetchapi(`/admin/email/${emailid}/send`, {
			recipients: JSON.stringify(to)
		})

		if (response?.success) {
			return true
		} else {
			return false
		}
	}
}

export const pathPrefix = window.cordova ? '.' : ''
export const isNative = !!(window.cordova)

export function openBrowser(url, target, options) {
	if (isNative) {
		window.cordova?.InAppBrowser?.open(url, target, options)
	} else {
		window.open(url, target, options)
	}
}

export function lazyComponent(name, props) {
	const LazyComponent = lazy(() => import(`../${name}`))

	return (
		<Suspense>
			<LazyComponent {...props} />
		</Suspense>
	)
}