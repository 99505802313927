import { lazyComponent } from 'lib/utils'

const adminRoutes = [
	{
		path: '/admin',
		element: lazyComponent('components/Admin/Dashboard'),
	},
	{
		path: '/admin/users',
		element: lazyComponent('components/Admin/Users'),
	},
	{
		path: '/admin/users/invite',
		element: lazyComponent('components/Admin/InviteUsers'),
	},
	{
		path: '/admin/courses',
		element: lazyComponent('components/Admin/Courses/Courses'),
	},
	{
		path: '/admin/course/:courseid',
		element: lazyComponent('components/Admin/Courses/EditCourse'),
	},
	{
		path: '/admin/course/:courseid/roster',
		element: lazyComponent('components/Admin/Courses/Roster'),
	},
	{
		path: '/admin/course/:courseid/:lessonid',
		element: lazyComponent('components/Admin/Courses/EditLesson'),
	},
	{
		path: '/admin/settings',
		element: lazyComponent('components/Admin/Settings/Settings'),
	},
	{
		path: '/admin/events',
		element: lazyComponent('components/Admin/Events/Events')
	}
]

export default adminRoutes
